body {
  display: grid;
  place-items: center;
  margin: 0;
  padding: 0 24px;
  background-image: url('../Images/bg.svg');
  background-size: 2000px 1000px;
  background-position: -500px 0;
  animation: gradient 10s infinite alternate linear;
}

#card-body-login {
  background-color: lightskyblue;
}

#toggleEye {
  position: absolute;
  right: 15px;
  top: 25px;
}
